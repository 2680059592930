export const TempusProject = () => ({
  comboboxType: "projects",
  nextElementId: "combobox-issues",
  close: true,

  initProject() {
    this.fetchProjects()
  },

  fetchProjects() {
    this.fetchGETSomething("/project/search", "&status=live")
      .then((result) => {
        this.itemlists["projects"] = []
        if (result.values) {
          for (const value of result.values) {
            this.projects[value.key] = value
            this.itemlists["projects"].push(value.key)
          }
        }
        this.itemlistsActive["projects"] = this.itemlists["projects"]
      })
      .catch((err) => console.log(err))
  },

  fetchProject(projectKey) {
    this.fetchGETSomething("/project/" + projectKey)
      .then((result) => {
        if (!result.err) {
          this.projects[result.key] = result
          return result
        }
      })
      .catch((err) => console.log(err))
  },

  setProject(projectKey) {
    if (this.projects.hasOwnProperty(projectKey)) {
      this.projectSelected = this.projects[projectKey]
    } else {
      this.projectSelected = this.fetchProject(projectKey)
    }
    if (
      this.issueSelected.hasOwnProperty("fields") &&
      this.issueSelected.fields.project.key != projectKey
    ) {
      this.issueSelected = {}
      this.issueSelectedData = {}
    }
    document.getElementById("combobox-projects").value =
      this.projectSelected.key + "- " + this.projectSelected.name
    return true
  },
})
